import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllGameActions extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`GameActions/GetAll`);
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

class DeleteGameAction extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`GameActions/Delete`);
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

class UpdateGameAction extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('GameActions/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateGameAction extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('GameActions/Create');
    }

    setParams(data) {
        super.setRequestParamDataObj(data)
    }
}

export {
    CreateGameAction,
    UpdateGameAction,
    DeleteGameAction,
    GetAllGameActions

};
