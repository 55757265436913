<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="gameActions !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          ok-title="بله"
          title="حذف "

          @ok="deleteGameAction"
      >
        <span>آیا از انجام این عملیات اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-edit"
          cancel-title="بستن"
          centered
          ok-title="ثبت"
          title="بروزرسانی "
          @ok.preventDefault()="updateGameAction"
      >
        <b-row v-if="selectedGameAction!==null">
          <!--          <b-col v-if="selectedGameAction.type!==6" cols="12">-->
          <!--            <b-form-group-->
          <!--                label="نوع"-->
          <!--                label-cols="12"-->
          <!--            >-->
          <!--              <v-select-->
          <!--                  v-model="selectedGameAction.type"-->
          <!--                  :clearable="false"-->
          <!--                  label="option"-->
          <!--                  :options="gameActionTypes"-->
          <!--                  :reduce="option => option.value"-->
          <!--              />-->
          <!--            </b-form-group>-->
          <!--          </b-col>-->

          <b-col cols="12">
            <b-form-group>
              <label>امتیاز</label>
              <b-form-input
                  v-model="selectedGameAction.score"
                  placeholder="امتیاز"
                  type="number"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="selectedGameAction.type!==6" cols="12">
            <b-form-group>
              <label>بازه</label>
              <b-form-input
                  v-model="selectedGameAction.counter"
                  placeholder="بازه"
                  type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
          id="modal-Add"
          cancel-title="بستن"
          centered
          ok-title="ثبت"
          title="افزودن "
          @ok.preventDefault()="createGameAction"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
                label="نوع"
                label-cols="12"
            >
              <v-select
                  v-model="gameAction.type"
                  :clearable="false"
                  label="option"
                  :options="gameActionTypes"
                  :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <label>امتیاز</label>
              <b-form-input
                  v-model="gameAction.score"
                  placeholder="امتیاز"
                  type="number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label>بازه</label>
              <b-form-input
                  v-model="gameAction.counter"
                  placeholder="بازه"
                  type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>


      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >
        <b-col class="my-2" lg="12" md="12">
          <b-row>

            <b-col class="my-2" lg="2" md="12">
              <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
            </b-col>
          </b-row>
        </b-col>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="gameActions"
            bordered
            :sort-by.sync="sortBy"
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >

          <template #cell(counter)="data">
            <span
                v-if="data.item.type===4">{{ Intl.NumberFormat('fa-IR').format(data.item.counter) }} <small>تومان</small> </span>
            <span v-else>{{ data.item.counter }}</span>

          </template>
          <template #cell(type)="data">
            <b-badge variant="primary">{{ getTypeName(data.item.type) }}</b-badge>
          </template>
          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">


              <div v-b-modal.modal-edit
                   class="cursor-pointer d-flex flex-row"
                   @click="setSelectedGameAction(data.item)"

              >
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </div>
              <!--              <div v-b-modal.modal-delete-->
              <!--                   class="cursor-pointer d-flex flex-row"-->
              <!--                   @click="setSelectedGameAction(data.item)"-->
              <!--              >-->
              <!--                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>-->
              <!--              </div>-->
            </div>
          </template>

        </b-table>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BFormTextarea, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {GetAllGameActions, CreateGameAction, UpdateGameAction, DeleteGameAction} from "@/libs/Api/gamification";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Helper from "@/libs/Helper";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  title: 'امتیازدهی  - پنل ادمین انزا مد',
  name: 'Actions',
  data() {
    return {
      showOverlay: false,
      gameActions: null,
      sortBy: '',
      gameAction: {
        type: null,
        counter: null,
        score: null,
        createDate: new Date(Date.now()),
      },
      selectedGameAction: null,
      gameActionTypes: [
        {
          option: 'بازدید',
          value: 1
        },
        {
          option: 'لایک',
          value: 2
        },
        {
          option: 'دیدگاه',
          value: 3
        },
        {
          option: 'خرید',
          value: 4
        },
        {
          option: 'ورود به سیستم',
          value: 5
        },
        {
          option: 'تکمیل حساب کاربری',
          value: 6
        },

      ],
      gameActionTypeEnums: {
        1: 'بازدید',
        2: 'لایک',
        3: 'دیدگاه',
        4: 'خرید',
        5: 'ورود به سیستم',
        6: 'تکمیل حساب کاربری',

      },
      myTableColumns: [
        {
          key: 'gameActionsId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'type',
          label: 'نوع',
          sortable: true
        },
        {
          key: 'counter',
          label: 'بازه',
          sortable: true
        },
        {
          key: 'score',
          label: 'امتیاز',
          sortable: true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },

      ],
    }
  },
  async created() {
    await this.getAllGameActions()
  },
  components: {
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },


  methods: {
    getTypeName(type) {
      return this.gameActionTypeEnums[type]
    },
    async updateGameAction() {
      let _this = this
      _this.showOverlay = true;
      let updateGameAction = new UpdateGameAction(_this)

      updateGameAction.setData(_this.selectedGameAction)
      await updateGameAction.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `بروزرسانی شد`,
          },
        })
        _this.selectedGameAction = null
        _this.showOverlay = false;
        _this.getAllGameActions();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async createGameAction() {
      let _this = this;
      _this.showOverlay = true;
      if (_this.gameAction.type && _this.gameAction.counter && _this.gameAction.score) {
        let createGameAction = new CreateGameAction(_this);
        createGameAction.setParams(_this.gameAction)
        await createGameAction.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `ساخته  شد`,
            },
          })
          _this.getAllGameActions();
          _this.gameAction = {
            type: null,
            counter: null,
            score: null,
            createDate: new Date(Date.now()),
          }
        }, function (error) {
          console.log(error)
        })
        _this.showOverlay = false;

      } else {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: `لطفا فیلد هارا پر کنید`,
          },
        })
      }
    },
    async deleteGameAction() {
      let _this = this
      let deleteGameAction = new DeleteGameAction(_this)
      deleteGameAction.setParams({gameActionId: this.selectedGameAction.gameActionId})
      await deleteGameAction.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `حذف شد.`,
          },
        })
        _this.getAllGameActions();
      }, function (error) {
        console.log(error)
      })
    },
    setSelectedGameAction(item) {
      this.selectedGameAction = JSON.parse(JSON.stringify(item))
    },
    async getAllGameActions() {
      let _this = this
      _this.showOverlay = true
      let getAllGameActions = new GetAllGameActions(_this)
      getAllGameActions.setParams({})
      await getAllGameActions.fetch(function (content) {
        _this.gameActions = content
      }, function (error) {
        console.log(error)
      })
      _this.showOverlay = false
    },
  },
}
</script>

<style scoped>

</style>
